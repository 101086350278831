.settings-table {
  thead {
    th {
      height: 50px;
      color: #101828;
    }
  }
  tbody {
    td {
      height: 60px;
      color: #475467;

      input.ant-input {
        width: 55px;
        padding: 2px 5px;
        border: none;
        border-bottom: 1px dashed #101828;
        background: transparent;
      }

      .tbl-op {
        span.dot {
          border-radius: 100%;
          height: 5px;
          width: 5px;
          background: black;
          font-weight: 600;
          font-size: 2rem;
          vertical-align: top;
          margin-left: 3px;
          margin-right: 3px;
        }

        &:hover .tbl-btn {
          opacity: 1;
        }
      }
    }
  }
}
.grupp-number {
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.grupp-number {
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.switch_user {
  width: 197px;

  p {
    width: 50%;
    background: #fff;
    color: #070707;
    padding: 0.65rem;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;

    &:first-of-type {
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:last-of-type {
      border-radius: 0.5rem;
    }
    &.active {
      color: #fafafa;
      transition: all 0.3s ease-in-out;
    }
  }
}
