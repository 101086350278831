.chat-sender,
.chat-receiver {
  width: fit-content;
}

.chat-sender {
  border-radius: 0 8px 8px 8px;
}

.chat-receiver {
  border-radius: 8px 0px 8px 8px;
  margin-left: auto;
  text-align: right;
}
