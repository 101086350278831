.onboarding-image {
  background-color: #f4f7f9;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-input:hover,
.ant-input-number:hover,
.ant-input-affix-wrapper:hover {
  border-color: #1377c4;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #1377c4;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #1377c4;
}

.ant-input:focus,
.ant-picker-focused,
.ant-picker:hover {
  border-color: #1377c4;
  box-shadow: none;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: transparent;
  box-shadow: none;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #1377c4;
  box-shadow: none;
}

.ant-menu-submenu-popup {
  opacity: 0;
  z-index: -10;
}

/** New design **/

.ant-radio-inner,
.ant-checkbox-inner {
  border: 1px solid #d0d5dd;
  &:after {
    border-color: #fff !important;
  }
}

.input-field {
  width: 100%;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  border: 1px solid #d0d5dd;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  color: #101828 !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  &.terminal-field {
    border: 1px solid #d0d5dd;
  }
  &.text-area {
    padding: 4px 11px;
  }
  .ant-input-number-input {
    width: 100%;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.03);
    border: 0;
    height: 48px;
    font-size: 16px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  &.ant-input-password {
    input.ant-input {
      background: transparent;
    }
  }
  &.ant-input[disabled] {
    background: #fff;
    color: #101828;
  }

  &.ant-picker {
    &.ant-picker-disabled {
      background: transparent;
    }
    .ant-picker-input input {
      color: #101828 !important;
    }
  }
  .ant-input-disabled {
    color: #101828;
  }
}

.ant-picker-input > input::placeholder {
  color: #101828;
}

label {
  color: #475467 !important;
  font-family: Avenir !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.submit-button {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  border-radius: 8px;
  height: 3rem !important;
  padding: 0.5rem;
}
.disable-item {
  color: #021230;
  cursor: not-allowed;
  font-weight: 400;
  &:hover {
    background: none;
  }
}

.input-addon {
  width: 100%;
  border-radius: 8px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  &.telephone {
    border: 1px solid #d0d5dd;
    .ant-input-wrapper {
      .ant-input-group-addon {
        border: 0;
        border-right: 1px !important;
        border-right-style: solid !important;
        border-right-color: #d0d5dd !important;
        background: #fff;
        font-size: 16px;
        color: #667085;
        font-weight: 400;
        line-height: 24px;
        border-radius: 8px 0 0 8px;
      }
    }
  }
  &.default {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border: 1px solid #d0d5dd;
  }
  &.error {
    border: 1px solid var(--Red-stroke, #fda29b);
    box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  &.success {
    border: 1px solid #32d583;
    box-shadow: 0px 0px 0px 4px #d1fadf, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .ant-input-wrapper {
    .ant-input-affix-wrapper {
      border: 0;
      border-right: 1px solid #f4f7f9;
      border-radius: 8px;
      padding: 0 11px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &.ant-input-affix-wrapper-status-success {
        border-right: 1px solid #32d583;
      }
    }
    .ant-input {
      height: 48px;
      font-family: Avenir;
      font-size: 16px;
      border: 0;
      background: #fff !important;
      border-radius: 8px;
      font-weight: 400;
      line-height: 24px;
      color: #101828 !important;
    }
    .ant-input-group-addon {
      border: 0;
      border-left: 1px !important;
      background: #fff;
      font-size: 16px;
      color: #667085;
      font-weight: 400;
      line-height: 24px;
      border-radius: 8px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.box-input {
  border-radius: 8px;
  border: 2px solid #021230;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #021230;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.96px;
  padding: 2px 8px;
  &:hover {
    border: 2px solid #021230;
  }
  &:focus {
    border: 2px solid #021230;
  }
}

.upload-progress {
  background: #f9f9f9;
  position: absolute;
  height: 133px;
  left: 0px;
  top: 0px;
  transition: width 2s ease-in-out 0s;
  border-radius: 8px;
  z-index: -20;
}

.auth-checkbox {
  &:hover {
    .ant-checkbox-inner {
      border-color: #667085;
    }
  }
  .ant-checkbox-checked {
    &::after {
      border-color: #667085;
    }
    .ant-checkbox-inner {
      background-color: #667085;
      border-color: #667085;
    }
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  .image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    &.visible {
      opacity: 1;
    }
    .slider_image {
      width: 100%;
      height: 580px;
      //max-height: 580px;
      object-fit: contain;
    }
  }
}

.custom-input-field {
  line-height: 46px;
  white-space: nowrap;
  text-overflow: ellipsis;
  .placeholder {
    color: #bfbfbf;
  }
}

.url-input {
  width: 100%;
  border-radius: 8px;
  padding: 10px, 14px, 10px, 14px;
  border: 1px solid #d0d5dd;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.radio-group {
  &.ant-radio-group .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #26847d !important;
    &::after {
      background-color: #26847d !important;
      border-color: #26847d !important;
    }
  }
}

.break-text {
  word-break: break-word;
}
