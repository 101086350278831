.btn-gradient-bg {
  background: linear-gradient(258.28deg, #079cbe 10.99%, #00497f 111.23%);
  &:hover,
  &:focus {
    background: linear-gradient(258.28deg, #10b5da 10.99%, #0563ac 111.23%);
    color: #abe3ee !important;
  }
}
.rounded-tab {
  border-radius: 20px 0px 0px 20px;
}
.last-tab {
  border-radius: 0px 20px 20px 0px;
}

.tab-button {
  border: 0;
  font-size: 14px;
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a1a1a1;
}

.tab-btn {
  outline: 0;

  &::after {
    content: "";
    box-shadow: none !important;
    --ant-wave-shadow-color: none !important;
  }

  &.set {
    background-color: #000;
    color: #fff;
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.btn {
  &-primary {
    @apply h-full flex items-center justify-center rounded-lg py-3 px-4 gap-2 font-extrabold text-white border-0 bg-[#26847D] active:bg-[#26847D]/70 focus:bg-[#26847D]/70 hover:bg-[#26847D]/70;
  }
}

.link-button {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border: 1px;
  border-style: solid;
  background: #fff;
}

.disabled {
  opacity: 0.6;
}
/*.tab {
  color: #667085;
  background-color: none !important;
  border: none;
  height: 2.5rem;
  font-size: 14px;
  font-weight: 600;

  &.active {
    color: #344054;
    background-color: #fff !important;
    border-radius: 6px;
  }

  outline: 0;

  &::after {
    content: "";
    box-shadow: none !important;
    --ant-wave-shadow-color: none !important;
  }

  &:hover,
  :focus {
    color: #667085 !important;
    background-color: #fff !important;
    border-radius: 6px;
  }
}*/
