.user-card {
  background-image: url("../assets/svg-v2/user-backdrop.svg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.customer .ant-modal-content .ant-modal-header {
  display: none;
}

.info_modal {
  .ant-modal-content {
    border-radius: 10px !important;
  }
}
.db-radio-menu {
  .ant-radio-checked .ant-radio-inner {
    border-color: #000;
  }

  .ant-radio-checked::after,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #000;
  }

  .ant-radio-inner::after {
    background-color: #000;
  }
}

/** New design */
.custom-shadow {
  border-radius: 12px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.custom-padding {
  padding: 24px;
}
.grupp-text {
  color: #475467;
}

.option-picker {
  background: transparent;
  text-transform: capitalize;
  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid #f4f7f9 !important;
    color: var(--gray-500, #101828);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    font-style: normal;
    .ant-select-selection-item {
      font-size: 14px !important;
      font-weight: 400;
    }
  }
  &.input-height {
    .ant-select-selector {
      height: 49px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
      border: 1px solid #d0d5dd !important;
      background: transparent !important;
      color: #101828 !important;
    }
  }
  &.multiple-picker {
    .ant-select-selector {
      min-height: 49px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      border: 1px solid #d0d5dd !important;
      background: transparent;
    }
  }
  &.currency {
    .ant-select-selector {
      height: 50px !important;
      border-radius: 12px !important;
      box-shadow: none;
      .ant-select-selection-item {
        line-height: 45px !important;
      }
    }
  }
  &.line-height {
    .ant-select-selection-search-input {
      height: 43px !important;
    }
  }
}

.ant-form-item-has-error .input-field {
  border-color: #ff4d4f !important;
}
.status-card {
  border-radius: 16px;
  mix-blend-mode: multiply;
  padding: 3px 8px;
  &.green {
    border: 1px solid var(--success-200, #abefc6);
    background: var(--success-50, #ecfdf3);
    color: #067647;
  }
  &.red {
    border: 1px solid var(--error-200, #fecdca);
    background: var(--error-50, #fef3f2);
    color: #b42318;
  }
  &.pending {
    border: 1px solid #f9dbaf;
    background: #fef6ee;
    color: #ffa500;
  }
  &.refund {
    border: 1px solid #87e8de;
    background: #e6fffb;
    color: #08979c;
  }
  &.blue {
    border-color: 1px solid #91caff;
    color: #0958d9;
    background: #e6f4ff;
  }
}
.input-search {
  border-radius: 8px !important;
  border: 1px solid var(--gray-300, #d0d5dd) !important;
  color: var(--gray-500, #667085);
  font-style: normal;

  & input {
    @apply text-sm font-avenir placeholder:font-avenir ml-2 text-[#667085]  font-extrabold;
  }
  .ant-input-suffix {
    align-items: flex-start;
  }
}
.map {
  div {
    border-radius: 8px;
  }
}

.new-modal {
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      border: 0;
      padding: 0;
      padding-bottom: 16px;
    }
  }
}

.new-image {
  border-radius: 200px;
  border: 4px solid var(--base-white, #fff);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.info-card-shadow {
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.tabs-box {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: #f9fafb;
  color: #1d2939;
  font-style: normal;
  font-weight: 600;
  .box {
    padding: 10px 16px;
    &:first-child {
      border-right: 1px solid var(--gray-300, #d0d5dd);
    }
    &.active {
      background: #fff;
      border-radius: 8px;
    }
  }
}
.upload-container {
  border-radius: 12px;
  border: 1.5px solid #d0d5dd;
  background: #fff;
}

.wallet-card {
  background-image: url(../assets/svg-v2/wallet-info-bg-top.svg),
    url(../assets/svg-v2/wallet-info-bg-bottom.svg);
  background-position: top right, bottom left;
  background-repeat: no-repeat, no-repeat;
}

.wallet-card-2 {
  background-image: url(../assets/svg-v2/wallet-bg-2.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
}

.doughnut-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem 0;
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.consumer-list {
  & thead > tr > th:first-child {
    padding-left: 2.8rem;
  }
}

.preview-image {
  position: relative;
  cursor: pointer;
  &::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #00000073;
    opacity: 0;
    transition: all 0.3s;
    content: " ";
  }
  &:hover {
    &::before {
      opacity: 1;
    }
    .preview-icon {
      opacity: 1;
    }
  }
  .preview-icon {
    position: absolute;
    z-index: 10;
    inset-inline-start: 0;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    opacity: 0;
    top: 45%;
  }
}

.fit-image {
  height: 250px;
  img {
    object-fit: contain;
  }
}
