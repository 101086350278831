@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Black_byfyes.ttf");
  font-weight: 900;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: 800;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: 600;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: bolder;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: bold;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335839/landing/fonts/Avenir/Avenir_Medium_r8yrlm.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Book_sfanvo.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Light_nzyszi.ttf");
  font-weight: 300;
}

* {
  font-family: Avenir !important;
}
.inter {
  font-family: "Inter" !important;
}
h2 {
  color: #121212;
}

b,
strong {
  font-weight: bold;
}

.general-color {
  color: #101828;
}

.mikro-gray {
  color: #a3abb7;
}

.mikro-gray-2 {
  color: #475467;
}

.mikro-dark {
  color: #00255a;
}

.mikro-dark-2 {
  color: #2c2c2c;
}

.mikro-blue {
  color: #00497f;
}

.mikro-green {
  color: #00be7c;
}

.mikro-red {
  color: #ff0000;
}

.mikro-color {
  color: #ad074b;
}

* {
  outline-color: #ebeaf5;
}

a:hover {
  color: #c6b135;
}
a:focus {
  color: #abe3ee;
}

.landing {
  a:hover {
    color: #182f43;
  }
  a:focus {
    color: #12525f;
  }
}

.ant-image-preview-img-wrapper {
  display: flex !important;
  justify-content: center !important;
}

.Typewriter {
  .Typewriter__wrapper {
    font-weight: 700;
  }
}
.ant-switch-checked {
  background-color: #26847d !important;
}
.ant-switch {
  border: 1px solid #26847d;
  .ant-switch-handle {
    top: 1px;
  }
}
.ant-switch-checked > .ant-switch-handle:before {
  background: #fff;
}
.ant-switch-handle:before {
  background: #26847d;
}
.landing {
  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
}

.auto-setl {
  background-color: #a1a1a1;
  &.ant-switch-checked {
    background-color: #3359de;
  }
}
.offer-btn:hover,
.offer-btn:focus {
  color: white;
  border-color: transparent;
}
.quill-grupp {
  &.filled {
    .ql-container.ql-snow {
      border-color: #1377c4;
    }
  }
  .ql-container.ql-snow {
    width: 100%;
    border-radius: 0px 10px 10px 0px;
    border: 0;
    border-left: solid 2px #000;
    height: 260px;
    background: rgba(0, 0, 0, 0.03);
  }
}
.table-date {
  &.ant-picker-focused {
    border-color: #a1a1a1 !important;
  }
  .ant-picker-active-bar {
    display: none;
  }
}
.custom-btn-shadow {
  box-shadow: 0px 10px 22px 0px rgba(2, 28, 67, 0.2);
}

.ant-input {
  line-height: 0.75;
}

.ant-table-tbody > tr > td {
  border-bottom: 0.35px solid #ebf1f5 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.ant-collapse-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-collapse-header-text {
  @apply text-base text-[#021230] font-medium font-avenir;
}

.ant-modal-content {
  @apply rounded-xl;
}

.custom-radio span:last-child {
  @apply text-[#475467] font-medium;
}

.ant-radio-group {
  width: 100%;
}

.ant-modal-close {
  top: 14px;
  right: 14px;

  &-x {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-radio-inner::after {
  background-color: #637bd6 !important;
  border-color: #637bd6 !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked:after,
.ant-radio-checked .ant-radio-inner {
  border-color: #000 !important;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #ddd;
}

.custom-rangepicker {
  & .ant-modal-content {
    overflow: hidden;
  }

  & .ant-modal-body {
    padding: 0;
  }
}

.no-header thead {
  display: none !important;
}

.ant-dropdown-menu.custom-dropdown {
  border-radius: 8px;
  padding: 6px;

  .dropdown-btn {
    @apply px-[14px] py-3 text-base text-[#021230] font-medium min-w-[300px] hover:bg-[#F9F9F9] text-left;
  }
}

.ant-upload.ant-upload-drag {
  background-color: transparent;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
}

.ant-upload-btn {
  padding: 16px 24px !important;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.gm-style-iw-t {
  top: -50px;
}

.no-upload-padding .ant-upload-btn {
  padding: 0 !important;
}

.button-shadow {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
