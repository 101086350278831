@import "./auth";
@import "./buttons";
@import "./business";
@import "./index.scss";
@import "./layout";
@import "./support";
@import "./settings";

.landing * {
  font-family: "Avenir", sans-serif !important;
}
