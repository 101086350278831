.site-layout {
  position: relative;
  background-color: #f7f8fc;
  margin-left: 0px;
  transition: margin 0.3s;
}

.mk-nav {
  position: fixed;
  background: #ffffff;
  width: calc(100% - 352px);
  top: 0;
  width: 100%;
  left: 0;
  z-index: 30;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.sub-nav {
    top: 64px;
  }
}

.app-content {
  min-height: calc(100vh - 64px);
  //zoom: 0.8;
  //transform: scale(0.8)
}

.sidebar {
  overflow: hidden auto;
  height: 100vh;
  position: fixed;
  left: 0px;
  z-index: 40;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.05);
  transition: width 0.1s;

  .logo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
  }
}

.menu {
  .ant-menu-item {
    color: #344054;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 16px 12px !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    &:hover {
      color: currentColor;
    }

    &::after {
      border: 0;
    }

    .anticon {
      width: 20px;
      font-size: 20px;
    }

    &.ant-menu-item-selected {
      background-color: var(--themeColor);
      color: #fff;
      border-radius: 8px;
      .icon-selected {
        path {
          stroke: white;
        }
      }
      &::before {
        transform: scaleY(1);
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }
}

.ant-menu-submenu-arrow {
  color: currentColor !important;
}

.ant-menu-submenu {
  color: #344054 !important;
  min-height: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  &.ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      background: var(--themeColor);
      border-radius: 8px;
      color: #fff;
      &:hover {
        color: #ffffff;
      }
    }
    .ant-menu-submenu-arrow {
      color: #fff !important;
    }

    .icon-selected {
      path {
        stroke: white;
      }
    }
  }

  .ant-menu-submenu-title {
    padding-left: 14px !important;
    height: 48px !important;
    background: transparent;
    .anticon {
      width: 20px;
      font-size: 20px;
    }
    &:hover {
      color: currentColor;
    }
  }
  .ant-menu {
    .ant-menu-item {
      padding-left: 40px !important;
      &.ant-menu-item-selected {
        background-color: var(--lightColor);
        color: #344054;
      }
    }
  }
}

@media (min-width: 1600px) {
  .site-layout {
    margin-left: 352px !important;
    &.collapse-layout {
      margin-left: 80px !important ;
    }
  }
  .mk-nav {
    width: calc(100% - 352px) !important;
    left: 352px !important;
    &.collapse-layout {
      width: calc(100% - 80px) !important;
      left: 80px !important;
    }
  }
}

@media (min-width: 1200px) {
  .site-layout {
    margin-left: 240px;
    &.collapse-layout {
      margin-left: 80px;
    }
  }
  .mk-nav {
    width: calc(100% - 240px);
    left: 240px;
    &.collapse-layout {
      width: calc(100% - 80px) !important;

      left: 80px;
    }
  }
  .ant-menu-submenu-popup {
    opacity: 1;
    z-index: 1050;
  }
}
